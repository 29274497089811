import React from 'react';
import { apiGet } from './api';
import {Table, TableBody, TableCell, TableHead, TableRow, Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

export default class Utenti extends React.Component {
  state={
    utenti: [],
    dominio:{},
  }
  componentDidMount() {
    var dominio=JSON.parse(localStorage.getItem("domain"));
    if (!dominio || dominio===null) {
      this.props.history.push("/domini");
      return;
    }
    this.props.cambiaTitolo("Utenti " + dominio.domain);
    apiGet("/users/"+dominio.domain, utenti => {
      this.setState( {utenti:utenti, dominio:dominio });
      this.props.cambiaTitolo("Utenti " + dominio.domain + " (" + utenti.length + "/"+dominio.users_max+")");
      //console.log(utenti);
    })
  }

	render() {
    const tooManyUsers=(this.state.utenti.length>=parseInt(this.state.dominio.users_max,10));
		return(
      <div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome utente</TableCell>
              <TableCell>Dominio</TableCell>
              <TableCell>Nome completo</TableCell>
              <TableCell>Email</TableCell>
              <TableCell align="right">Quota</TableCell>
              <TableCell align="right">Dimensioni</TableCell>
              <TableCell align="right">%</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.utenti.map(u=>(
              <TableRow key={u.mail} hover onClick={() => this.props.history.push("/utenti/" + u.username)}>
                <TableCell>{u.username}</TableCell>
                <TableCell>{u.domain}</TableCell>
                <TableCell>{u.name}</TableCell>
                <TableCell>{u.mail}</TableCell>
                <TableCell align="right">{u.quota}</TableCell>
                <TableCell title={u.quota_upd} align="right">{u.quota_used}</TableCell>
                <TableCell align="right">{Math.round(parseInt(u.quota_used,10)/parseInt(u.quota,10)*100)}%</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Fab disabled={ tooManyUsers } color="primary" onClick={() => this.props.history.push("/utenti/new")} style={{position:"fixed", bottom:50,right:50}}>
          <AddIcon />
        </Fab>
      </div>
		 );
	 }
}
