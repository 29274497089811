import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';

class SSDialog extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: this.props.open,
			title: this.props.title,
			message: this.props.message
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.open!==this.state.open)  {
			this.setState( {
				open: nextProps.open,
				title: nextProps.title,
				message: nextProps.message
			} );
		}
	}

	render() {
		return(
			 <Dialog
				 open={this.state.open}
				 onClose={this.handleClose}
				 aria-labelledby="alert-dialog-title"
				 aria-describedby="alert-dialog-description"
			 >
				 <DialogTitle id="alert-dialog-title">{this.state.title}</DialogTitle>
				 <DialogContent>
					 <DialogContentText id="alert-dialog-description">
						 {[this.state.message]}
					 </DialogContentText>
				 </DialogContent>
				 <DialogActions>
					 <Button onClick={this.props.handleClose} color="primary">
						 Chiudi
					 </Button>
				 </DialogActions>
			 </Dialog>
		 );
	 }
}

export default SSDialog;
