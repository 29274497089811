import React from 'react';
import { apiGet, apiPut, apiPost, apiDelete } from './api';
import { TextField, FormGroup, Radio, RadioGroup, FormControlLabel, FormLabel, Button } from '@material-ui/core';

export default class UtentiDettagli extends React.Component {
  state={
    utente:{
      username:'',
      domain:'',
      password_new:'',
      password_confirm:'',
      name:'',
      mail:'',
      quota:0,
      creato:'',
      domain_admin:'',
      server_admin:'',
      nuovo:false,
    },
    permissions: {
      server_admin: false,
      domain_admin: false
    },
    domain: {
      quota_max: null,
      quota_default: null,
    },
    modificato: false
  }

  componentDidMount() {
    var dominio=JSON.parse(localStorage.getItem("domain"));
    if (!dominio || dominio===null) {
      this.props.history.push("/domini");
      return;
    }

    //var dominio=localStorage.getItem("dominio");
    var username=this.props.match.params.utente;

    this.props.cambiaTitolo("Utente " + username + "@" + dominio.domain);

    apiGet("/permissions/" + dominio.domain, permissions=> {
      this.setState( {permissions:permissions });
      apiGet("/domains/" + dominio.domain, domain=> {
        if (domain.length===0)
          return;
        this.setState( {domain:domain[0] });
        if (username==='new') {
          this.assegnaDefault(dominio.domain);
        } else {
          this.caricaUtente(username,dominio.domain);
        }
      });
    });
  }

  caricaUtente= (username,dominio) => {
    apiGet("/users/" + dominio + "/" + username, utente => {
      if (utente.length===0)
        return;
      utente=utente[0];
      utente.password_new='';
      utente.password_confirm='';
      const original = Object.assign({}, utente);
      this.setState( {utente: utente, utente_original: original} );
    });
  }

  assegnaDefault=(dominio) => {
    var utente=this.state.utente;
    utente.domain=dominio;
    utente.quota=this.state.domain.quota_default;
    utente.nuovo=true;
    const original = Object.assign({}, utente);
    this.setState( {utente: utente, utente_original: original} );
  }

  verifica = () => {
    var errori=[];
    var utente=this.state.utente;
    if (!utente.username)
      errori.push('Username mancante');
    if (utente.nuovo && utente.password_new==='')
      errori.push('Password mancante')
    if (utente.password_new !== utente.password_confirm)
      errori.push('Le password non coincidono');
    if (this.state.domain.quota_max && utente.quota > this.state.domain.quota_max)
      errori.push('Quota superiore al massimo consentito (' + this.state.domain.quota_max + "MB)");
    return errori;
  }

  salva = () => {
    var errori=this.verifica();
    if (errori.length>0)  {
      alert("Impossibile salvare: \n" + errori.join("\n"));
      return;
    }

    //var id=this.props.match.params.utente;
    var utente=this.state.utente;
    var utente_new={};
    utente_new.username=utente.username;
    utente_new.domain=utente.domain;
    if (utente.password_new) {
      utente_new.password=utente.password_new;
    }
    utente_new.quota=utente.quota;
    utente_new.name=utente.name;
    if (utente.nuovo) {
      apiPost("/users",JSON.stringify(utente_new),(data)=>{
        this.props.history.push("/utenti/" + utente.username);
      });
    } else {
      apiPut("/users",JSON.stringify(utente_new),(data)=>{
        this.props.history.push("/utenti/" + utente.username);
      });
    }
  }

  annulla=()=>{
    if (!window.confirm('Confermi l\'annullamento di tutte le modifiche pendenti non salvate?'))
      return;
    const utente = Object.assign({}, this.state.utente_original);
    this.setState( {utente: utente, modificato: false} );
  }

  elimina=()=>{
    if (!window.confirm('Confermi l\'eliminazione dell\'utente?\nATTENZIONE: notificare l\'eliminazione a Roberto perché per cancellare definitivamente un utente vanno rimosse manualmente alcune impostazioni'))
      return;
    apiDelete("/users/" + this.state.utente.username + "@" + this.state.utente.domain, () => {
      this.props.history.push("/utenti");
    });
  }

  handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		var utente=this.state.utente;
		utente[name]=value;
		this.setState({	utente:utente, modificato: true });
	}

	render() {
    return(
      <div>
        <FormGroup>
          <TextField margin="normal" required disabled={!this.state.utente.nuovo} label="Username" name="username" value={this.state.utente.username} onChange={this.handleInputChange} />
        </FormGroup>
        <FormGroup>
          <TextField margin="normal" disabled label="Dominio" name="domain" value={this.state.utente.domain} onChange={this.handleInputChange} />
        </FormGroup>
        <FormGroup>
          <TextField margin="normal" type="password" label="Modifica password" name="password_new" value={this.state.utente.password_new} onChange={this.handleInputChange} />
        </FormGroup>
        <FormGroup>
          <TextField margin="normal" type="password" label="Conferma nuova password" name="password_confirm" value={this.state.utente.password_confirm} onChange={this.handleInputChange} />
        </FormGroup>
        <FormGroup>
          <TextField margin="normal" label="Nome visualizzato" name="name" value={this.state.utente.name} onChange={this.handleInputChange} />
        </FormGroup>
        <FormGroup>
          <TextField margin="normal" disabled label="Indirizzo email" name="mail" value={this.state.utente.mail} />
        </FormGroup>
        <FormGroup>
          <TextField margin="normal" type="number" label={"Quota MB" + (this.state.domain.quota_max ? ' (' + this.state.domain.quota_max + 'MB massimo)' : '' )} name="quota" value={this.state.utente.quota} onChange={this.handleInputChange}  />
        </FormGroup>
        <FormGroup>
          <TextField margin="normal" disabled label="Data creazione" name="creato" value={this.state.utente.creato} />
        </FormGroup>
        {this.state.permissions.server_admin && (
        <FormGroup>
          <FormLabel margin="normal" component="legend">Amministratore dominio</FormLabel>
          <RadioGroup style={{display: 'flex', flexWrap: 'nowrap', flexDirection: 'row'}} margin="normal" name="domain_admin" onChange={this.handleInputChange} value={this.state.utente.domain_admin} >
            <FormControlLabel value="0" control={<Radio />} label="No" />
            <FormControlLabel value="1" control={<Radio />} label="Sì" />
          </RadioGroup>
          <FormLabel margin="normal" component="legend">Amministratore server</FormLabel>
          <RadioGroup style={{display: 'flex', flexWrap: 'nowrap', flexDirection: 'row'}} margin="normal" name="server_admin" onChange={this.handleInputChange} value={this.state.utente.server_admin} >
            <FormControlLabel value="0" control={<Radio />} label="No" />
            <FormControlLabel value="1" control={<Radio />} label="Sì" />
          </RadioGroup>
        </FormGroup>)}
        <Button disabled={this.state.utente.username===''} onClick={this.salva} variant="contained" color="primary">Salva</Button>
        &nbsp;
        <Button disabled={!this.state.modificato} onClick={this.annulla} variant="contained" color="default">Annulla</Button>
        &nbsp;
        <Button disabled={this.state.utente.username===''} onClick={this.elimina} variant="contained" color="secondary">Elimina</Button>
      </div>
    );
  }
}
