import React from 'react';
import { Button, Paper, Typography, TextField, FormGroup, Grid } from '@material-ui/core'
import Dialog from "./Dialog"
import {apiGet} from "./api"
import { setUser, clearUser } from "./auth"

const style = {
	paddingLeft: "10px",
	paddingRight: "10px",
	paddingTop: "20px",
	paddingBottom: "20px",
	height: "100%"
};

const logoStyle = {
	display: "block",
	marginLeft: "auto",
	marginRight: "auto",
	width: "60%",
	maxWidth: "500px"
}

class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			username: '',
			password: '',
			fornitore: '',
			dialog_open: false,
			dialog_message: ''
		}
	}


	handleLogin = (e) => {
		var username=this.state.username;
		var password=this.state.password;
		var errors=[];

		setUser(username,password,errors);

		if (errors.length>0)  {
			clearUser();
			this.setState({
				dialog_open: true,
					dialog_message: (errors.length===1?"Errore: ":"Errori (" + errors.length + "): ") + errors.join(", ")
			});
			return;
		}

		apiGet("/auth",(data)=>{
			if (data==="ok")	{
				window.location.reload();
			} else {
				clearUser();
				this.setState({
					dialog_open: true,
						dialog_message: "Nome utente o password non validi."
				});
			}
		});
	}

	handleCloseDialog = () => {
		this.setState({dialog_open: false});
	}

	handleCancel = (e) => {
		this.setState({username:'',password:''});
	}

	handleInputChange  = (e) => {
		const target = e.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});
	}

	render() {
		return (
			<div>
				<Dialog open={this.state.dialog_open} title="Login" message={[this.state.dialog_message]} handleClose={this.handleCloseDialog} />
				<Paper style={style}>
					<img src="logo.png" style={logoStyle} alt="Sapsystems"/>
					<Typography variant="h5" gutterBottom>Login</Typography>
					<FormGroup>
						<TextField name="username" label="Nome utente" value={this.state.username} onChange={this.handleInputChange} />
						<TextField name="password" label="Password" type="password" value={this.state.password} onChange={this.handleInputChange} />
					</FormGroup>
					<br />
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<Button variant="contained" color="primary" fullWidth={true} onClick={this.handleLogin} >Accedi</Button>
						</Grid>
						<Grid item xs={6}>
							<Button variant="contained" color="secondary" fullWidth={true} onClick={this.handleCancel} >Annulla</Button>
						</Grid>
					</Grid>
				</Paper>
			</div>
		);
	}
}

export default Login;
