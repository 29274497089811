export function getUser()	{
	var user={};
	user.username=localStorage.getItem("username");
	user.password=localStorage.getItem("password");
}

export function setUser(username, password, errors)	{
	if (username==="")
		errors.push("nome utente mancante");
	if (password==="")
		errors.push("password mancante");

	if (errors.length>0)  {
		return false;
	}

	localStorage.setItem("username", username);
	localStorage.setItem("password", password);

	return true;
}

export function clearUser()	{
	localStorage.setItem("username", "");
	localStorage.setItem("password", "");
}

export function isLoggedIn() {
	return (localStorage.getItem("username") && localStorage.getItem("password"));
}
