import React from 'react';
import { apiGet } from './api';
import {Table, TableBody, TableCell, TableHead, TableRow, Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

export default class Alias extends React.Component {
  state={
    alias: [],
    dominio: {},
  }


  componentDidMount() {
    var dominio=JSON.parse(localStorage.getItem("domain"));
    if (!dominio || dominio===null) {
      this.props.history.push("/domini");
      return;
    }
    this.props.cambiaTitolo("Alias " + dominio.domain );
    apiGet("/aliases/"+dominio.domain, alias => {
      this.setState( {alias:alias});
    });
  }

	render() {
		return(
      <div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome utente</TableCell>
              <TableCell>Dominio</TableCell>
              <TableCell>Alias</TableCell>
              <TableCell>Destinazione</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.alias.map(a=>(
              <TableRow key={a.alias} hover onClick={() => this.props.history.push("/alias/"+a.username)}>
                <TableCell>{a.username}</TableCell>
                <TableCell>{a.domain}</TableCell>
                <TableCell>{a.alias}</TableCell>
                <TableCell>{a.destination}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Fab color="primary" onClick={() => this.props.history.push("/alias/new")} style={{position:"fixed", bottom:50,right:50}}>
          <AddIcon />
        </Fab>
      </div>
		 );
	 }
}
