import React from 'react';
import { apiGet } from './api';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

//import { Link } from "react-router-dom";


export default class Domini extends React.Component {
  state={
    domini: []
  }
  componentDidMount() {
    this.props.cambiaTitolo("Domini");
    apiGet("/domains", domini => {
      this.setState( {domini:domini});
    })
  }

  handleClick = dominio => {
    localStorage.setItem("dominio",dominio.domain);
    localStorage.setItem("domain",JSON.stringify(dominio));
    this.props.history.push("/utenti");
  }

	render() {
		return(
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Dominio</TableCell>
            <TableCell>Backup MX</TableCell>
            <TableCell>Max Utenti</TableCell>
            <TableCell>Max Quota</TableCell>
            <TableCell>Quota Default</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.domini.map(d=>(
            <TableRow hover onClick={ () => this.handleClick(d) } key={d.domain}>
              <TableCell>{d.domain}</TableCell>
              <TableCell>{d.backup_mx}</TableCell>
              <TableCell>{d.users_max}</TableCell>
              <TableCell>{d.quota_max}</TableCell>
              <TableCell>{d.quota_default}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
		 );
	 }
}
