import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import { ExitToApp } from 'mdi-material-ui';
import { Earth, AccountMultiple, AccountArrowRight } from 'mdi-material-ui';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { styles } from './styles';
import { withStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
//import { apiGet } from './api';

const logo = (
	<div className={styles.toolbar}>
		<img src="/logo.png" style={ {width: "100%"} } alt="Gestione mail server"/>
	</div>);



class Navigation extends React.Component {
	state = {
		menu:[],
	}


	menu = () => {
		return (
			<div>
				<Divider />
				<List>
					<ListItem button component={Link} to="/domini">
						<ListItemIcon>
							<Earth />
						</ListItemIcon>
						<ListItemText primary="Domini" />
					</ListItem>
					<ListItem button component={Link} to="/utenti">
						<ListItemIcon>
							<AccountMultiple />
						</ListItemIcon>
						<ListItemText primary="Utenti" />
					</ListItem>
					<ListItem button component={Link} to="/alias">
						<ListItemIcon>
							<AccountArrowRight />
						</ListItemIcon>
						<ListItemText primary="Alias" />
					</ListItem>
					<Divider />
					<ListItem button onClick={this.props.onLogoff}>
						<ListItemIcon>
							<ExitToApp />
						</ListItemIcon>
						<ListItemText primary="Disconnessione" />
					</ListItem>
				</List>
			</div>
		)
	}



	render()	{
		const { classes } = this.props;

		return(
			<div>
				<Hidden mdUp>
				  <Drawer
					variant="temporary"
					anchor='left'
					open={this.props.mobileOpen}
					onClose={this.props.onDrawerToggle}
					classes={{
					  paper: classes.drawerPaper,
					}}
					ModalProps={{
					  keepMounted: true, // Better open performance on mobile.
					}}
				  >
					{logo}
					{this.menu()}
				  </Drawer>
				</Hidden>
				<Hidden smDown implementation="css">
				  <Drawer
					variant="permanent"
					open
					classes={{
					  paper: classes.drawerPaper,
					}}
				  >
					{logo}
					{this.menu()}
				  </Drawer>
				</Hidden>
			</div>);
	}
}
export default withStyles(styles, { withTheme: true })(Navigation);
