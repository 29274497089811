import React from 'react';
import { BrowserRouter as Router,  Route,  Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import Navigation from './Navigation';
import Header from './Header';
import Login from './Login';
import { clearUser, isLoggedIn } from './auth';
import Domini from './Domini';
import Utenti from './Utenti';
import UtentiDettagli from './UtentiDettagli';
import Alias from './Alias';
import AliasDettagli from './AliasDettagli';

class App extends React.Component {
  state = {
    mobileOpen: false,
	title: 'Gestione mail server',
  };

  handleLogoff = () => {
	  clearUser();
	  this.setState({ title: 'Uscita' });
  }

  cambiaTitolo = (titolo) =>	{
	  this.setState({ title: titolo });
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  render() {
    const { classes } = this.props;

	if (!isLoggedIn())  {
		return <Login />
	}
    return (
		<Router>
			<div className={classes.root}>
				<Header onDrawerToggle={this.handleDrawerToggle} title={this.state.title}/>
				<Navigation
				mobileOpen={this.state.mobileOpen}
				onDrawerToggle={this.handleDrawerToggle}
				onLogoff={this.handleLogoff}
				/>
				<main className={classes.content}>
					<div className={classes.toolbar} />

						<Switch>
							<Route path="/domini" exact render={(props) => <Domini {...props} cambiaTitolo={this.cambiaTitolo}  />} />
              <Route path="/utenti" exact render={(props) => <Utenti {...props} cambiaTitolo={this.cambiaTitolo}  />} />
              <Route path="/utenti/:utente" exact render={(props) => <UtentiDettagli {...props} cambiaTitolo={this.cambiaTitolo}  />} />
              <Route path="/alias" exact render={(props) => <Alias {...props} cambiaTitolo={this.cambiaTitolo}  />} />
              <Route path="/alias/:utente" exact render={(props) => <AliasDettagli {...props} cambiaTitolo={this.cambiaTitolo}  />} />
						</Switch>

				</main>
			</div>
		</Router>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(App);
