export const apiBaseURL = "https://mail.sapsystems.it/api";

export function apiGet(url,callBack)	{
	const username = localStorage.getItem("username");
	const password = localStorage.getItem("password");

	var headers = new Headers();

	headers.append('Authorization', 'Basic ' + btoa(username + ":" + password));

	return fetch(apiBaseURL+url, {method:'GET',
	        headers: headers,
		}).then( res=> {
			if (res.ok)	{
				var contentType=res.headers.get("content-type");
				if (! (contentType.indexOf("text/html")>=0 || contentType.indexOf("json")>=0 ) ) 	{
					return res.blob();
				}	else {
					return res.json();
				}
			} else {
				switch (res.status)	{
					case 401:
						console.log("Non autorizzato");
						return({errore:res.status});
					default:
						return({errore:res.status});
				}
			}
		}).then( data => {
			if (callBack) {
				callBack(data);
			} else {
				window.open(window.URL.createObjectURL(data));
			}
		});
}

export function apiPost(url,body,callBack)	{
	const username = localStorage.getItem("username");
	const password = localStorage.getItem("password");
	//const apiBaseURL = "https://insubriamedica.sapsystems.it/api"

	var headers = new Headers();

	headers.append('Authorization', 'Basic ' + btoa(username + ":" + password));

	return fetch(apiBaseURL+url, {method:'POST',
	        headers: headers,
			body: body,
		}).then( res=> {
			if (res.ok)	{
				return res.json();
			} else {
				switch (res.status)	{
					case 401:
						console.log("Non autorizzato");
						return({errore:res.status});
					default:
						return({errore:res.status});
				}
			}
		}).then( data => {
			callBack(data);
		});
}

export function apiPut(url,body,callBack)	{
	const username = localStorage.getItem("username");
	const password = localStorage.getItem("password");
	//const apiBaseURL = "https://insubriamedica.sapsystems.it/api"

	var headers = new Headers();

	headers.append('Authorization', 'Basic ' + btoa(username + ":" + password));

	return fetch(apiBaseURL+url, {method:'PUT',
	        headers: headers,
			body: body,
		}).then( res=> {
			if (res.ok)	{
				return res.json();
			} else {
				switch (res.status)	{
					case 401:
						console.log("Non autorizzato");
						return({errore:res.status});
					default:
						return({errore:res.status});
				}
			}
		}).then( data => {
			callBack(data);
		});
}

export function apiDelete(url,callBack)	{
	const username = localStorage.getItem("username");
	const password = localStorage.getItem("password");
	//const apiBaseURL = "https://insubriamedica.sapsystems.it/api"

	var headers = new Headers();

	headers.append('Authorization', 'Basic ' + btoa(username + ":" + password));

	return fetch(apiBaseURL+url, {method:'DELETE',
			headers: headers,
		}).then( res=> {
			if (res.ok)	{
				return res.json();
			} else {
				switch (res.status)	{
					case 401:
						console.log("Non autorizzato");
						return({errore:res.status});
					default:
						return({errore:res.status});
				}
			}
		}).then( data => {
			callBack(data);
		});
}



export function eliminaNull(object) {
	for (var property in object) {
	    if (object.hasOwnProperty(property)) {
			if (!object[property] || object[property]==='0000-00-00')
				object[property]='';
	    }
	}
	return object;
}
