import React, { useState, useEffect } from 'react';
import { apiGet, apiPut, apiPost, apiDelete } from './api';
import { TextField, FormGroup, Button } from '@material-ui/core';

export default function  AliasDettagli (props) {
  const [dominio, setDominio] = useState();
  const [alias, setAlias] = useState({});
  const [aliasOriginal, setAliasOriginal] = useState({});


  useEffect(() => {
    const stdominio=JSON.parse(localStorage.getItem("domain"));
    if (!stdominio || stdominio===null) {
      return;
    }
    setDominio(stdominio);
  }, []);

  useEffect(() => {
    if (!dominio) return;
    if (props.match.params.utente==="new")  {
      const aliasNew={nuovo:true,domain:dominio.domain,username:'',destination:''};
      setAlias(aliasNew);
      setAliasOriginal(JSON.parse(JSON.stringify(aliasNew)));
      props.cambiaTitolo("Nuovo alias @" + dominio.domain);
    } else {
      apiGet("/aliases/"+dominio.domain+"/"+props.match.params.utente, data => {
        setAlias(data[0]);
        setAliasOriginal(JSON.parse(JSON.stringify(data[0])));
        props.cambiaTitolo("Alias " + data[0].username + "@" + dominio.domain);
      });
    }

  }, [dominio]);

  const handleInputChange = e => {
    const aliasNew={...alias};
		aliasNew[e.target.name]=e.target.value;
    aliasNew.modificato=true;
    setAlias(aliasNew);
	}

  const verifica = () => {
    var errori=[];
    if (!alias.username)
      errori.push('Username mancante');

    if (alias.destination==='')
      errori.push('Nessuna destinazione inserita');

    alias.destination = alias.destination.replace(/(\r\n|\n|\r)/gm, " ").replace(/ +(?= )/g,'').trim();
    alias.destination.split(' ').forEach(d=>{
      if ( ! /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(d) ) {
        errori.push('Indirizzo email non valido: ' + d);
      }
    });
    return errori;
  }

  const salva = ()=>{
    var errori=verifica();
    if (errori.length>0)  {
      alert("Impossibile salvare: \n" + errori.join("\n"));
      return;
    }

    const aliasNew={};
    aliasNew.username=alias.username;
    aliasNew.domain=alias.domain;
    aliasNew.destination=alias.destination;
    if (props.match.params.utente==='new')  {
      apiPost("/aliases",JSON.stringify(aliasNew),data=>{
        props.history.push("/alias/" + alias.username);
      });
    } else {
      apiPut("/aliases",JSON.stringify(aliasNew),data=>{
        props.history.push("/alias/" + alias.username);
      });
    }

  }

  const annulla = ()=>{
    setAlias(JSON.parse(JSON.stringify(aliasOriginal)));
  }

  const elimina = ()=>{
    if (!window.confirm('Confermi l\'eliminazione dell\'alias?'))
      return;
    apiDelete("/aliases/" + alias.username + "@" + alias.domain, () => {
      props.history.push("/alias");
    });
  }

  if (!alias || Object.keys(alias).length === 0) return "";

  return (<div>
          <FormGroup>
            <TextField margin="normal" required={alias.nuovo} disabled={!alias.nuovo} label="Username" name="username" value={alias.username} onChange={handleInputChange} />
          </FormGroup>
          <FormGroup>
            <TextField margin="normal" disabled label="Dominio" name="domain" value={alias.domain} />
          </FormGroup>
          <FormGroup>
            <TextField multiline margin="normal" label="Destinazioni (separate da spazio)" name="destination" value={alias.destination} onChange={handleInputChange} />
          </FormGroup>

          <Button disabled={alias.username==='' || !alias.modificato} onClick={salva} variant="contained" color="primary">Salva</Button>
          &nbsp;
          <Button disabled={!alias.modificato} onClick={annulla} variant="contained" color="default">Annulla</Button>
          &nbsp;
          <Button disabled={alias.username===''} onClick={elimina} variant="contained" color="secondary">Elimina</Button>
        </div>);
}
