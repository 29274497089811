import React from 'react';
import { AppBar,Toolbar,IconButton,Typography } from '@material-ui/core';
import { Menu } from 'mdi-material-ui';
import { styles } from './styles';
import { withStyles } from '@material-ui/core/styles';


class Header extends React.Component {
	render()	{
		const { classes } = this.props;

		return (
			<AppBar className={classes.appBar}>
	          <Toolbar>
	            <IconButton
	              color="inherit"
	              aria-label="open drawer"
	              onClick={this.props.onDrawerToggle}
	              className={classes.navIconHide}
	            >
	            <Menu />
	            </IconButton>
	            <Typography variant="h6" color="inherit" noWrap>
	            	{this.props.title}
	            </Typography>
	          </Toolbar>
	        </AppBar>
		)
	}
}

export default withStyles(styles, { withTheme: true })(Header);
